import React from "react"
import Helmet from "react-helmet"
import TY from "src/components/typage"

export default function ThankYouAmo() {
  return(
    <>
    <Helmet>
     <style type="text/css">{`
    `}
    </style>
    <title>Thank You for Installing EasyPrint by Clear</title>
    </Helmet>
    <TY></TY>
    </>
  )
}
